
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  MultiCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    MultiCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 5;
    const router = useRouter();

    const title = "Are you currently taking any of the following medications?";
    const options = [
      {
        label: "Phenobarbital",
        value: "Phenobarbital",
      },
      {
        label: "Bosentan",
        value: "Bosentan",
      },
      {
        label: "Carbamazepine",
        value: "Carbamazepine",
      },
      {
        label: "Felibamate",
        value: "Felibamate",
      },
      {
        label: "Griseofulvin",
        value: "Griseofulvin",
      },
      {
        label: "Oxcarbazapine",
        value: "Oxcarbazapine",
      },
      {
        label: "Phenytoin or Fosphenytoin",
        value: "Phenytoin or Fosphenytoin",
      },
      {
        label: "Primidone",
        value: "Primidone",
      },
      {
        label: "Rifampin",
        value: "Rifampin",
      },
      {
        label: "St John's Wort",
        value: "St John's Wort",
      },
      {
        label: "Topiramate",
        value: "Topiramate",
      },
      {
        label: "Efavirenz",
        value: "Efavirenz",
      },
      {
        label: "Nevirapine",
        value: "Nevirapine",
      },
      {
        label: "None of the Above",
        value: "NIL",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
    };
  },
});
